import { DefaultTheme } from 'styled-components';
import { Alpha, Breakpoints, Colors, Fonts, Theme } from '@makeship/core';
import { CampaignStatus } from '../../types/common';

export const colors: Colors = {
  neutral7: '#141414',
  neutral6: '#282828',
  neutral5: '#505050',
  neutral4: '#7C7C7C',
  neutral3: '#ABABAB',
  neutral2: '#DDDDDD',
  neutral1: '#FFFFFF',
  primaryLight: '#668EFF',
  primary: '#5349ED',
  primaryDark: '#2D23B6',
  secondaryLight: '#7FF25F',
  secondary: '#4EE12A',
  secondaryDark: '#1B7D02',
  greenNeutral: '#7ff260',
  accent: '#F5F0D5',
  accentDark: '#E7E1C0',
  info: '#5349ED',
  success: '#028848',
  warning: '#EB9D10',
  warningLight: '#FFEFD3',
  error: '#C0323B',
  background: '#F5F7F8',
  grey01: '#DDDDDD',
  grey02: '#ABABAB',
  grey03: '#7C7C7C',
  grey04: '#505050',
  highlight: '#EE4DFF',
  transparent: 'transparent',
  campaigns: {
    [CampaignStatus.onboarding]: {
      primary: '#141414',
    },
    [CampaignStatus.verification]: {
      primary: '#C0323A',
    },
    [CampaignStatus.development]: {
      primary: '#9B51E0',
    },
    [CampaignStatus.productDesign]: {
      primary: '#9B51E0',
    },
    [CampaignStatus.launched]: {
      primary: '#5349ED',
    },
    [CampaignStatus.actionRequired]: {
      primary: '#C0323A',
    },
    [CampaignStatus.creatorActionPending]: {
      primary: '#C0323A',
    },
    [CampaignStatus.adminPayoutProcessing]: {
      primary: '#C0323A',
    },
    [CampaignStatus.paidOut]: {
      primary: '#028848',
    },
    [CampaignStatus.unsuccessful]: {
      primary: '#970036',
    },
  },
};

const breakpoints: Breakpoints = {
  mobile: 480,
  tablet: 768,
  largeTablet: 900,
  desktop: 1080,
  largeDesktop: 1280,
  maxContent: 1440,
  maxBackground: 1920,
  headerBreakpoint: 1050,
};

export const alpha: Alpha = {
  extraDark: 75,
  dark: 60,
  medium: 33,
  light: 15,
  extraLight: 10,
};

export const fonts: Fonts = {
  primary: 'Proxima Nova, sans-serif',
  secondary: 'Aktiv Grotesk, sans-serif',
  button: 'Proxima Nova, sans-serif',
  buttonWeight: 400,
};

const header = {
  height: '64px',
};

const baseTheme: Theme = {
  colors: { ...colors },
  breakpoints: { ...breakpoints },
  alpha: { ...alpha },
  fonts: { ...fonts },
  header: { ...header },
};

export const light: DefaultTheme = {
  ...baseTheme,
};

export const dark: DefaultTheme = {
  ...baseTheme,
};
