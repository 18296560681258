import styled, { css, CSSProp } from 'styled-components';
import { utils } from '@makeship/core';
import { P2Styles } from '../../Typography';

export const InputStyles = css`
  ${P2Styles}
  width: 100%;
  outline: none;
  padding: 10px;
  background: none;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.neutral7};
  &::-webkit-input-placeholder,
  &::placeholder {
    color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.medium)};
  }
`;

const Input = styled.input<{ error?: boolean; customStyles?: CSSProp }>`
  ${InputStyles}
  ${({ customStyles }) => customStyles && customStyles}
  border: 1px solid ${({ error, theme }) => (error ? theme.colors.error : theme.colors.primaryLight)};
`;

const TextArea = styled.textarea<{ error?: boolean }>`
  ${InputStyles}
  resize: none;
  min-height: 132px;
  border: 1px solid ${({ error, theme }) => (error ? theme.colors.error : theme.colors.primaryLight)};
`;

const BorderInput = {
  Input,
  TextArea,
};

export default BorderInput;
