import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import { P2Styles, Overline, Caption, H5Styles2, P2 } from '../../Typography';
import DropdownDownIcon from '../../../assets/images/selection-down-chevron.svg';
import DropdownUpIcon from '../../../assets/images/selection-up-chevron.svg';

const DropdownContainer = styled.div<{ error?: boolean }>`
  border-radius: 3px;
  width: 100%;
  height: 48px;
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.error : theme.colors.primaryLight)};
  margin-bottom: 16px;
  &:hover {
    cursor: pointer;
  }
  display: grid;
`;

const DropdownTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  width: 100%;
  height: 48px;
  &:hover {
    cursor: pointer;
  }
`;

const Chevron = styled.img<{ showDropdown: boolean }>`
  fill: red;
  padding-left: 8px;
  margin-right: 8px;
  display: ${({ showDropdown }) => (showDropdown ? 'inline' : 'none')};
`;

const DropdownListWrapper = styled.div`
  will-change: filter;
  background: ${({ theme }) => theme.colors.neutral1};
  position: relative;
  top: 0px;
  border-radius: 3px;
  z-index: 3;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 240px;
  overflow-y: auto;
`;

const DropdownOption = styled.div<{ noResults: boolean | undefined }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  min-height: 44px;
  &:hover {
    ${({ theme, noResults }) =>
      noResults ? '' : `background: ${utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};`}
`;

const DropdownText = styled(P2)`
  margin-left: 8px;
`;

const DropdownInput = styled.input`
  margin-left: 8px;
  border: none;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const LabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RequiredLabel = styled(Overline)`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  margin-left: 8px;
`;

const InputLabel = styled.label<{ unbold: boolean | undefined }>`
  ${({ unbold }) => (unbold ? P2Styles : H5Styles2)};
  display: inline-block;
  margin-bottom: 6px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`;

const NotFoundOption = styled(P2)`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
`;

const SubText = styled(Caption)`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  justify-self: flex-start;
`;

const ErrorMessage = styled(Caption)<{ hide: boolean }>`
  color: ${({ theme }) => theme.colors.error};
  display: ${({ hide }) => (hide ? 'none' : 'display')};
`;

export const searchFilter = (searchValue: string, list: string[], autoComplete?: boolean) => {
  if (!autoComplete) {
    return list;
  }

  const lowerCaseQuery = searchValue.toLowerCase();
  const filteredList = searchValue ? list.filter((x) => x.toLowerCase().includes(lowerCaseQuery)) : list;

  return filteredList.length ? filteredList : ['No matches found'];
};

export type DropdownProps = {
  dropdownText?: string;
  options: string[];
  handleSelect: (option: string) => void;
  id: string;
  label: string;
  value: string;
  error?: boolean;
  errorMessage?: string;
  unbold?: boolean;
  hasRequiredText?: boolean;
  subText?: string;
  autoComplete?: boolean;
  testID?: string;
  onClick?: () => void;
};

const Dropdown: React.FC<DropdownProps> = ({
  dropdownText,
  options,
  handleSelect,
  label,
  id,
  hasRequiredText,
  unbold,
  subText,
  error,
  errorMessage,
  value,
  autoComplete,
  testID,
}: DropdownProps) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');

  const dropDownWrapperRef = useRef<HTMLDivElement>(null);

  const SPACEBAR_KEY_CODE = 'Space';
  const ENTER_KEY_CODE = 'Enter';
  const ESCAPE_KEY_CODE = 'Escape';

  function CheckForClicksOutsideDropdown(ref: HTMLDivElement | null) {
    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function handleClickOutside(event: { target: any }) {
        if (ref && !ref.contains(event.target)) {
          if (showDropdown) handleShowDropdown(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, showDropdown]);
  }

  function handleDropdownKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.code === ESCAPE_KEY_CODE) handleShowDropdown(false);
    if (event.code === ENTER_KEY_CODE) {
      // Prevent spacebar from scrolling page down when focus is on this element
      event.preventDefault();
      handleShowDropdown(true);
    }
  }

  function handleOptionKeyPressSelect(option: string) {
    return function handleSpaceKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
      if (event.code === ESCAPE_KEY_CODE) handleShowDropdown(false);
      if (event.code === SPACEBAR_KEY_CODE || event.code === ENTER_KEY_CODE) {
        event.preventDefault();
        handleSelect(option);
        handleShowDropdown(false);
      }
    };
  }

  const handleAutoCompleteChange = (value: string) => {
    setSearchValue(value);
  };

  const handleShowDropdown = (isVisible: boolean) => {
    setShowDropdown(isVisible);
    if (!isVisible) {
      setSearchValue('');
    }
  };

  CheckForClicksOutsideDropdown(dropDownWrapperRef.current);

  return (
    <DropdownWrapper ref={dropDownWrapperRef} data-testid="dropdown-wrapper">
      <LabelsWrapper>
        {label && (
          <InputLabel unbold={unbold} htmlFor={id}>
            {label}
          </InputLabel>
        )}
        {hasRequiredText && <RequiredLabel>Required</RequiredLabel>}
      </LabelsWrapper>
      <DropdownContainer
        data-testid="dropdown-container"
        onClick={() => handleShowDropdown(!showDropdown)}
        error={error}
      >
        <DropdownTextWrapper tabIndex={0} onKeyDown={handleDropdownKeyPress}>
          {!autoComplete || !showDropdown ? (
            <DropdownText>{value || dropdownText || ''}</DropdownText>
          ) : (
            <DropdownInput
              data-testid="dropdown-input"
              autoFocus
              defaultValue={value}
              onChange={(e) => handleAutoCompleteChange(e.target.value)}
            />
          )}
          <Chevron showDropdown={!showDropdown} alt="Selection chevron icon" src={DropdownDownIcon} />
          <Chevron showDropdown={showDropdown} alt="Selection chevron icon" src={DropdownUpIcon} />
        </DropdownTextWrapper>
        {showDropdown && (
          <DropdownListWrapper>
            <FlexBox>
              {searchFilter(searchValue, options, autoComplete).map((option) => {
                if (option === 'No matches found') {
                  return (
                    <DropdownOption noResults data-testid="dropdown-result">
                      <NotFoundOption>{option}</NotFoundOption>
                    </DropdownOption>
                  );
                }
                return (
                  <DropdownOption
                    noResults={false}
                    data-testid="dropdown-result"
                    key={option}
                    onClick={() => handleSelect(option)}
                    tabIndex={0}
                    onKeyDown={handleOptionKeyPressSelect(option)}
                  >
                    <P2>{option}</P2>
                  </DropdownOption>
                );
              })}
            </FlexBox>
          </DropdownListWrapper>
        )}
        {!showDropdown && subText && <SubText>{subText}</SubText>}
        <ErrorMessage hide={!error}>{errorMessage}</ErrorMessage>
      </DropdownContainer>
    </DropdownWrapper>
  );
};

export default Dropdown;
