import React from 'react';
import styled from 'styled-components';
import loadingAnimation from '../../../assets/images/loading.gif';

const LoadingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 100;
`;

const Loading: React.FC = () => (
  <LoadingWrapper>
    <img src={loadingAnimation} alt="loading animation" />
  </LoadingWrapper>
);

export default Loading;
