import cookie from 'cookie';

export const accessTokenKey = 'accessToken';
export const refreshTokenKey = 'refreshToken';

export const createCookie = (cookieName: string, cookieValue: string, hourToExpire: number): void => {
  const date = new Date();
  date.setTime(date.getTime() + hourToExpire * 60 * 60 * 1000);
  document.cookie = `${cookieName} = ${cookieValue}; expires = ${date.toUTCString()}; SameSite = Strict; Secure; path=/`;
};

export const getCookie = (cookieName: string): string => cookie.parse(document.cookie)[cookieName];

export const deleteCookie = (cookieName: string): void => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
