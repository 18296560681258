import React from 'react';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import { H5Style2, Caption } from '../../Typography';
import defaultProductImg from '../../../assets/images/default-product.png';

export type CardProps = {
  item: Campaign;
};

const CardContainer = styled.div`
  display: flex;
  height: 100%;
  padding: 5px;
`;

const CardInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const CardH5 = styled(H5Style2)`
  margin-bottom: 2px;
`;

const CardCaption = styled(Caption)`
  margin-bottom: 2px;
`;

const CardIcons = styled.div`
  flex-basis: 80px;
  display: flex;
  padding-top: 10px;
`;

const Card: React.FC<CardProps> = ({ item }: CardProps) => (
  <CardContainer>
    <CardInfo>
      <CardH5>{item.campaignName}</CardH5>
      <CardCaption>{item.contact?.firstName}</CardCaption>
    </CardInfo>
    <CardIcons>
      <div>
        <img src={item.product?.imageUrl || defaultProductImg} alt="plush toy" />
      </div>
    </CardIcons>
  </CardContainer>
);

export const PrimaryCard = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral1};
  box-shadow: 0px 0px 6px ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  border-radius: 3px;

  &:hover {
    /* background-color: ${({ theme }) => theme.colors.accent}; */
    box-shadow: 0px 0px 16px ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  }
`;

export default Card;
