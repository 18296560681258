import { AxiosResponse } from 'axios';
import client from '../client';
import { removeErrorName } from '../../utils/cognito-error';

export interface AuthResponse {
  accessToken: string;
  idToken: string;
  refreshToken?: string;
  challengeName?: string;
  challengeParameters?: Record<string, string>;
  session?: string;
}

export const refreshRoute = '/auth/refresh';

export const signIn = async (email: string, password: string): Promise<AxiosResponse<AuthResponse>> =>
  client
    .post<AuthResponse>('/auth/login', { email, password })
    .catch((err) => Promise.reject(removeErrorName(err.response.data.detail)));

export const signUp = async (email: string, password: string): Promise<AxiosResponse<AuthResponse>> =>
  client
    .post<AuthResponse>('/auth/signup', { email, password })
    .catch((err) => Promise.reject(removeErrorName(err.response.data.detail)));

export const confirmSignUpUsingCode = async (email: string, code: string): Promise<AxiosResponse<AuthResponse>> =>
  client
    .post<AuthResponse>('/auth/signup/confirm', { email, code })
    .catch((err) => Promise.reject(removeErrorName(err.response.data.detail)));

export const refreshAccessToken = async (refreshToken: string, userId?: string): Promise<AxiosResponse<AuthResponse>> =>
  client
    .post<AuthResponse>(refreshRoute, { refreshToken, userId })
    .catch((err) => Promise.reject(removeErrorName(err.response.data.detail)));

export const signOut = async (): Promise<AxiosResponse<AuthResponse>> =>
  client.post<AuthResponse>('/auth/signout').catch((err) => Promise.reject(removeErrorName(err.response.data.detail)));

export const forgotPassword = async (email: string): Promise<AxiosResponse<AuthResponse>> =>
  client
    .post<AuthResponse>('/auth/password/forgot', { email })
    .catch((err) => Promise.reject(removeErrorName(err.response.data.detail)));

export const confirmForgotPassword = async (
  email: string,
  password: string,
  confirmationCode: string,
): Promise<AxiosResponse<AuthResponse>> =>
  client
    .post<AuthResponse>('/auth/password/confirm', { email, password, confirmationCode })
    .catch((err) => Promise.reject(removeErrorName(err.response.data.detail)));

export const respondToAuthChallenge = async (
  challengeName: string,
  challengeResponse: string,
  email: string,
  session: string,
): Promise<AxiosResponse<AuthResponse>> =>
  client
    .post<AuthResponse>('/auth/challenge/respond', { challengeName, challengeResponse, email, session })
    .catch((err) => Promise.reject(removeErrorName(err.response.data.detail)));

export const updatePassword = async (oldPassword: string, newPassword: string): Promise<AxiosResponse<AuthResponse>> =>
  client
    .post<AuthResponse>('/auth/password/update', { oldPassword, newPassword })
    .catch((err) => Promise.reject(removeErrorName(err.response.data.detail)));

export const sendInvitation = async (email: string): Promise<AxiosResponse<AuthResponse>> =>
  client
    .post<AuthResponse>('/auth/invitation', { email })
    .catch((err) => Promise.reject(removeErrorName(err.response.data.detail)));

export const resendVerifyCode = async (email: string): Promise<AxiosResponse<AuthResponse>> =>
  client
    .post<AuthResponse>('/auth/code/resend', { email })
    .catch((err) => Promise.reject(removeErrorName(err.response.data.detail)));
