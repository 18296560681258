import React, { Dispatch, SetStateAction } from 'react';
import styled, { CSSProp, createGlobalStyle } from 'styled-components';
import { utils } from '@makeship/core';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, RawDraftContentState } from 'draft-js';
import BorderInput, { InputStyles } from './borderInput';
import { P2Styles, Overline, Caption, H5Styles2 } from '../../Typography';
import Tooltip from '../Tooltip';
import QuestionMark from '../../../assets/images/question-icon.svg';

const { Input, TextArea } = BorderInput;

export type InputProps = {
  type: string;
  step?: string;
  id: string;
  label: string;
  placeholder: string;
  editorState?: EditorState;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeTextArea?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onEditorStateChange?: Dispatch<SetStateAction<EditorState>>;
  onChangeEditorArea?: (contentState: RawDraftContentState) => void;
  required?: boolean;
  spellcheck?: string;
  error?: boolean;
  errorMessage?: string;
  children?: React.ReactNode;
  styles?: CSSProp;
  unbold?: boolean;
  min?: string;
  hasRequiredText?: boolean;
  subText?: string;
  maxLength?: number;
  hasToolTip?: boolean;
  toolTipText?: string;
  onClick?: () => void;
};

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  margin-bottom: 16px;
`;

const InputLabel = styled.label<{ unbold: boolean | undefined }>`
  ${({ unbold }) => (unbold ? P2Styles : H5Styles2)};
  display: inline-block;
  margin-bottom: 6px;
`;

const ErrorMessage = styled(Caption)<{ hide: boolean }>`
  color: ${({ theme }) => theme.colors.error};
  display: ${({ hide }) => (hide ? 'none' : 'display')};
`;

const RequiredLabel = styled(Overline)`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  margin-left: 8px;
`;

const LabelsWrapper = styled.div`
  display: flex;
`;

const RightAlignItems = styled.div`
  margin-left: auto;
  display: flex;
`;

const SubText = styled(Caption)`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  justify-self: flex-start;
`;

const EditorWrapperStyles = styled.div<{ error?: boolean }>`
  ${InputStyles}
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.error : theme.colors.primaryLight)};
  height: 186px;
`;

const EditorStyles = createGlobalStyle`
  .editor {
    width: 460px;
    height: 80%;
  }
  .linkPopup {
    height: 250px;
  }
`;

const InputField: React.FC<InputProps> = ({
  label,
  id,
  value,
  editorState,
  error,
  errorMessage,
  unbold,
  hasRequiredText = false,
  subText,
  maxLength,
  hasToolTip,
  toolTipText,
  type,
  placeholder,
  onChangeTextArea,
  onEditorStateChange,
  onChangeEditorArea,
  onClick,
  ...rest
}: InputProps) => {
  const renderInputAreaFor = (type: string) => {
    switch (type) {
      case 'textarea':
        return (
          <TextArea
            onClick={onClick}
            onChange={onChangeTextArea}
            placeholder={placeholder}
            error={error}
            id={id}
            value={value}
          />
        );
      case 'richText':
        return (
          <EditorWrapperStyles error={error}>
            <Editor
              stripPastedStyles
              onFocus={onClick}
              onChange={onChangeEditorArea}
              onEditorStateChange={onEditorStateChange}
              editorState={editorState}
              toolbar={{
                options: ['inline', 'link'],
                inline: {
                  options: ['bold', 'italic', 'underline'],
                },
                link: {
                  popupClassName: 'linkPopup',
                },
              }}
              wrapperClassName="editor"
            />
            <EditorStyles />
          </EditorWrapperStyles>
        );
      default:
        return (
          <Input
            onClick={onClick}
            {...rest}
            maxLength={maxLength}
            id={id}
            value={value}
            error={error}
            placeholder={placeholder}
          />
        );
    }
  };

  return (
    <InputWrapper>
      <LabelsWrapper>
        {label && (
          <InputLabel unbold={unbold} htmlFor={id}>
            {label}
          </InputLabel>
        )}
        <RightAlignItems>
          {hasToolTip && toolTipText && (
            <Tooltip content={toolTipText}>
              <img src={QuestionMark} alt="Tooltip help icon" />
            </Tooltip>
          )}
          {hasRequiredText && <RequiredLabel>Required</RequiredLabel>}
        </RightAlignItems>
      </LabelsWrapper>
      {renderInputAreaFor(type)}
      {subText && <SubText>{subText}</SubText>}
      <ErrorMessage hide={!error}>{errorMessage}</ErrorMessage>
    </InputWrapper>
  );
};

export default InputField;
