import React from 'react';
import ReactDom from 'react-dom';
import { Amplify } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import posthog from 'posthog-js';

import App from './containers/App';
import awsconfig from './aws-exports';

import './assets/styles/normalize.css';
import './assets/styles/global.css';

const SENTRY_DSN = process.env.REACT_APP_PUBLIC_SENTRY_DSN || '';
const ENVIRONMENT = process.env.REACT_APP_PUBLIC_ENV || '';
const POSTHOG_SDK_KEY = process.env.REACT_APP_POSTHOG_PORTAL_SDK_KEY || '';

Amplify.configure(awsconfig);
Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.2,
  ignoreErrors: ['Failed to fetch', 'cancelled', 'NetworkError'],
  // Ignore Recaptcha false positive error - https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
  beforeSend(event, hint) {
    if (hint.originalException === 'Timeout') return null;
    return event;
  },
});

posthog.init(POSTHOG_SDK_KEY, {
  api_host: 'https://app.posthog.com',
  autocapture: false,
});

ReactDom.render(<App />, document.querySelector('#root'));
