import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { utils } from '@makeship/core';
import { H8Styles, P2 } from '../../Typography';
import DropdownIcon from '../../../assets/images/dropdown-icon-purple.svg';

export type DropdownProps = {
  options?: string[];
  onClick: (selectedOption: string) => void;
};

const DropdownContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 2px;

  &:hover {
    cursor: pointer;
  }
`;

const Chevron = styled.img`
  fill: red;
  padding-left: 8px;
`;

const Selector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DropdownSelected = styled.div`
  ${H8Styles}
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    box-shadow: 0 2px 0 ${({ theme }) => theme.colors.secondary};
  }
`;

const DropdownListWrapper = styled.div`
  background: ${({ theme }) => theme.colors.neutral1};
  position: absolute;
  top: 53px;
  left: 113px;
  box-shadow: 0px 0px 16px ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  border-radius: 3px;
  z-index: 3;
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DropdownOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 336px;
  padding: 0 16px;

  &:hover {
    background: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  }
`;

type PathToDropdown = {
  [key: string]: string;
};
const pathToDropdown: PathToDropdown = {
  '/dashboard': 'Campaigns',
  '/verifications': 'Verification Requests',
  '/payouts': 'Payout Requests',
  '/clientCut': 'Client Cut',
};

const Dropdown: React.FC<DropdownProps> = ({ options, onClick, ...rest }: DropdownProps) => {
  const location = useLocation();

  const ref = React.useRef<HTMLDivElement>(null);
  const [currentView, setCurrentView] = useState<string>();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleClick = useCallback((e: MouseEvent) => {
    // outside dropdown div
    if (!!ref.current && !ref.current.contains(e.target as Node)) {
      clear();
    }
  }, []);

  const handleSelect = (option: string) => {
    setCurrentView(option);
    onClick(option);
  };

  useEffect(() => {
    setCurrentView(pathToDropdown[location.pathname]);
  }, [location.pathname]);

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  const clear = () => {
    setShowDropdown(false);
  };
  return (
    <DropdownContainer ref={ref} onClick={() => setShowDropdown(!showDropdown)}>
      <Selector>
        <DropdownSelected>{currentView}</DropdownSelected>
        {options && options?.length > 1 && <Chevron src={DropdownIcon} />}
      </Selector>
      {showDropdown && (
        <DropdownListWrapper>
          <FlexBox>
            {options?.map((option) => (
              <DropdownOption onClick={() => handleSelect(option)}>
                <P2>{option}</P2>
              </DropdownOption>
            ))}
          </FlexBox>
        </DropdownListWrapper>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
