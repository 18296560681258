import React from 'react';
import styled, { css } from 'styled-components';
import { utils } from '@makeship/core';
import { P2 } from '../../Typography';

export type AvatarProps = {
  name?: string;
  initial?: string;
  color?: string;
  tooltip?: boolean;
  large?: boolean;
  children?: React.ReactNode;
};

const AvatarContainer = styled.div<{ color: string; large: boolean; tooltip: boolean }>`
  width: ${({ large }) => (large ? 40 : 32)}px;
  height: ${({ large }) => (large ? 40 : 32)}px;
  color: ${({ theme }) => theme.colors.neutral1};
  background: ${({ color, theme }) => color || theme.colors.primaryDark};
  border-radius: 50px;
  display: inline-block;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${({ tooltip }) => tooltip && 'cursor: pointer;'}
`;

const AvatarText = styled(P2)`
  text-transform: uppercase;
`;

const TooltipCSS = css`
  visibility: hidden;
  background-color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  color: ${({ theme }) => theme.colors.neutral1};
  text-align: center;
  padding: 6px 0;
  border-radius: 3px;
  position: absolute;
  z-index: 10;
`;

const Tooltip = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 115%;
  position: absolute;
  display: flex;
  cursor: pointer;
  ${TooltipCSS}
`;

const TooltipText = styled(P2)`
  ${TooltipCSS}
  white-space: nowrap;
  padding: 8px 16px;
  ${AvatarContainer}:hover & {
    visibility: visible;
  }
`;

const Avatar: React.FC<AvatarProps> = ({
  name = '',
  color = '',
  initial = '',
  tooltip = false,
  large = false,
  children,
}: AvatarProps) => (
  <AvatarContainer color={color} large={large} tooltip={tooltip}>
    {children || <AvatarText>{initial || name.charAt(0)}</AvatarText>}
    {tooltip && (
      <Tooltip>
        <TooltipText>{name}</TooltipText>
      </Tooltip>
    )}
  </AvatarContainer>
);

export default Avatar;
