import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { utils } from '@makeship/core';

import Logo from '../../../assets/images/redesign_logo_makeship.svg';

type HeaderProps = {
  children: React.ReactNode;
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  background-color: ${({ theme }) => theme.colors.neutral1};
  padding: 15px 24px;
  border-bottom: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
`;

const FlexBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
`;

const LogoWrapper = styled.div`
  cursor: pointer;
`;

const LogoImg = styled.img`
  width: 39px;
  height: 39px;
`;

const StyledHeader: React.FC<HeaderProps> = ({ children }: HeaderProps) => (
  <Header>
    <Link to="/dashboard">
      <LogoWrapper>
        <LogoImg src={Logo} alt="Makeship logo" />
      </LogoWrapper>
    </Link>
    <FlexBox>{children}</FlexBox>
  </Header>
);

export default StyledHeader;
