/* eslint-disable */

const awsmobile = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_AWS_OAUTH_DOMAIN,
    scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
    redirectSignIn: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGNIN,
    redirectSignOut: '',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['GOOGLE', 'FACEBOOK'],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: [],
};

export default awsmobile;
