import styled, { css } from 'styled-components';

export const H1Styles = css`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 0.02em;
`;

export const H1 = styled.h1`
  ${H1Styles}
`;

export const H2Styles = css`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 54px;
  letter-spacing: 0.02em;
`;

export const H2 = styled.h2`
  ${H2Styles}
`;

export const H2Styles2 = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 43px;
  letter-spacing: 0.05em;
`;

export const H2Style2 = styled.h2`
  ${H2Styles}
`;

export const H3Styles = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.02em;
`;

export const H3 = styled.h3`
  ${H3Styles}
`;

export const H3Styles2 = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.05em;
`;

export const H3Style2 = styled.h3`
  ${H3Styles}
`;

export const H4Styles = css`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.02em;
`;

export const H4 = styled.h4`
  ${H4Styles}
`;

export const H4Style2 = styled.h4`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
`;

export const H5Styles = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.02em;
`;

export const H5 = styled.h5`
  ${H5Styles}
`;

export const H5Styles2 = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
`;

export const H5Style2 = styled.h5`
  ${H5Styles}
`;

export const H6Styles = css`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.02em;
`;

export const H6 = styled.h6`
  ${H5Styles}
`;

export const H8Styles = css`
  //styleName: Subtitle 2 - H8 - Website;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
`;

export const H8 = styled.h6`
  ${H8Styles}
`;

export const S1Styles = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
`;

export const S1 = styled.p`
  ${S1Styles}
`;

export const S2Styles = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
`;

export const S2 = styled.p`
  ${S2Styles}
`;

export const S3Styles = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
`;

export const S3 = styled.p`
  ${S3Styles}
`;

export const P1Styles = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
`;

export const P1 = styled.p`
  ${P1Styles}
`;

export const P1Style2 = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

export const P2Styles = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
`;

export const P2 = styled.p`
  ${P2Styles}
`;

export const CaptionStyles = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.04em;
`;

export const Caption = styled.p`
  ${CaptionStyles}
`;

export const OverlineStyles = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.04em;
`;

export const Overline = styled.p`
  ${OverlineStyles}
`;

export const OverlineStyles2 = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

export const OverlineStyle2 = styled.p`
  ${OverlineStyles}
`;

export const DialogStyles = css`
  font-family: ${({ theme }) => theme.fonts.button};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
`;

export const Dialog = styled.p`
  ${DialogStyles}
`;

export const Strong = styled.span`
  font-weight: 700;
`;

export const Italics = styled.span`
  font-style: italic;
`;
