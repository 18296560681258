export enum CampaignStatus {
  onboarding = 'Onboarding',
  verification = 'Verification Request',
  development = 'Campaign Prep',
  productDesign = 'Product Design',
  launched = 'Campaign Launch',
  actionRequired = 'Payout Request',
  creatorActionPending = 'Creator Pending',
  adminPayoutProcessing = 'Admin Payout Processing',
  paidOut = 'Paid Out',
  unsuccessful = 'Unsuccessful',
}

export enum UserType {
  SuperAdmin = 'superadmin',
  Admin = 'admin',
  Creator = 'creator',
  Sales = 'sales',
}

type RoleType = {
  [key: string]: string;
};
export const RoleTypes: RoleType = {
  Primary: 'primary',
  Sales: 'sales',
  Viewer: 'viewer',
};

export enum PaymentMethod {
  PayPal = 'paypal',
  BankTransfer = 'bank',
}

export enum VerificationStatus {
  unverified = 'unverified',
  submitted = 'submitted',
  verified = 'verified',
  approved = 'approved',
}

export enum ApprovalStatus {
  approved = 'approved',
  rejected = 'rejected',
}

export enum PaymentStatus {
  submitted = 'submitted',
  approved = 'approved',
  rejected = 'rejected',
}

type ProductMOQ = {
  [key: string]: number;
};

export const productMOQ: ProductMOQ = {
  Plushie: 200,
  Longboi: 200,
  Charm: 100,
  Sketchbook: 500,
  Keycap: 100,
  Backpack: 200,
  EnamelPin2Pack: 200,
  EnamelPin3Pack: 200,
};

export enum TableType {
  campaigns = 'campaigns',
  verifications = 'verifications',
  payouts = 'payouts',
  clientCut = 'clientCut',
}

export enum ContractType {
  standard = 'standard',
  nonStandard = 'non-standard',
}

export enum TaskType {
  AgreementPrep = 'agreementPrep',
  ProductPagePrep = 'productPagePrep',
  PayoutReview = 'payoutReview',
}

export const getTableType = (tableType: TableType) => {
  switch (tableType) {
    case TableType.campaigns:
      return 'Campaigns';
    case TableType.clientCut:
      return 'Client Cuts';
    case TableType.payouts:
      return 'Payouts';
    case TableType.verifications:
      return 'Verifications';
    default:
      return '';
  }
};

export const gracePeriod = 14;

export enum AccountType {
  Individual = 'individual',
  Company = 'company',
}

export enum AccountName {
  SameAsInvoice = 0,
  DifferentName = 1,
}

export enum PayoutSteps {
  invoiceApproval,
  payment,
  verification,
  review,
}

export enum AgreementPrepSteps {
  campaignInfo,
  review,
  confirmation,
}

export enum ProductPagePrepSteps {
  campaignDetails,
  creatorMessage,
  review,
  confirmation,
}

export enum BreadcrumbType {
  payout = 'payout',
  agreementPrep = 'agreementPrep',
  productPagePrep = 'productPagePrep',
}

export enum ProductType {
  Plushie = 'Plush Toy',
  Longboi = 'Longboi',
  JumboPlush = 'Jumbo Plush',
  PlushKeychain = 'Keychain',
  SquishyPlush = 'Squishy',
  Vinyl = 'Vinyl',
  Charm = 'Charm',
  FannyPack = 'Fanny Pack',
  EnamelPin2Pack = 'Enamel Pin 2 Pack',
  EnamelPin3Pack = 'Enamel Pin 3 Pack',
}

export const ProductTypeList: string[] = Object.entries(ProductType).map(([_key, value]) => value);

export enum PayoutTerms {
  StandardPlushPayoutTerms = '0% (campaign fails) if less than 200 units are sold, 10% if 200-399 units are sold, 30% if 400+ units are sold.',
  StandardFlatRatePayoutTerms = '30% of net retail sales if moq is reached.',
  StandardVinylPayoutTerms = '0% (campaign fails) if less than 500 units are sold, 10% if 500-999 units are sold, 30% if 1000+ units are sold.',
  StandardFannyPackPayoutTerms = '0% (campaign fails) if less than 200 units are sold, 30% if 200+ units are sold.',
  StandardEnamelPin2PackPayoutTerms = '0% (campaign fails) if less than 200 units are sold, 10% if 200-399 units are sold, 30% if 400+ units are sold.',
  StandardEnamelPin3PackPayoutTerms = '0% (campaign fails) if less than 200 units are sold, 10% if 200-399 units are sold, 30% if 400+ units are sold.',
}
