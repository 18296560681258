import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';

import { refreshAccessToken, refreshRoute } from './auth';
import { accessTokenKey, createCookie, getCookie, refreshTokenKey } from '../utils/cookies';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  },
});

client.interceptors.request.use((req: AxiosRequestConfig) => {
  if (req.data) {
    req.data = qs.stringify(req.data);
  }

  if (req.url === refreshRoute) return req;

  const accessToken = getCookie(accessTokenKey);

  if (accessToken) {
    req.headers.Authorization = `Bearer ${accessToken}`;
    return req;
  }

  return getAccessToken(req);
});

const getAccessToken = (req: AxiosRequestConfig) => {
  const refreshToken = getCookie(refreshTokenKey);
  const userId = window.localStorage.getItem('userId') || undefined;

  if (!refreshToken) {
    return req;
  }

  return refreshAccessToken(refreshToken, userId)
    .then(({ data: { accessToken } }) => {
      // expire in 30 minutes(same time as the cookie is invalidated on the backend)
      createCookie(accessTokenKey, accessToken, 0.5);

      const bearer = `Bearer ${accessToken}`;
      req.headers.Authorization = bearer;
      client.defaults.headers.common.Authorization = bearer;

      return req;
    })
    .catch(() => req);
};

export default client;
