import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { utils, Button } from '@makeship/core';
import { Link } from 'react-router-dom';
import { H4Style2, Caption, OverlineStyle2 } from '../../Typography';

import Avatar from '../Avatar';
import Header from './index';
import { PrimaryCard } from '../Card';

import { AuthContext } from '../../../contexts/auth-context';
import { UserType } from '../../../types/common';

import { fullName } from '../../../utils/name';
import { ReactComponent as SettingsIcon } from '../../../assets/images/settings-icon.svg';
import { DropdownSelected } from '../Dropdown';
import ResendInvite from '../../admin/ResendInvite';

const NavItems = styled.div`
  display: flex;
`;

const RightIcons = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
`;

const PersonInfoContainer = styled.div`
  position: relative;
  margin-left: 24px;
`;

const ResendInviteContainer = styled.div`
  position: relative;
`;

const AvatarWrapper = styled.div`
  display: flex;
  &:hover {
    cursor: pointer;
  }
`;

const DropdownWrapper = styled.div`
  padding-left: 40px;
`;

const CardWrapper = styled(PrimaryCard)<{ show: boolean }>`
  position: absolute;
  top: 38px;
  left: -192px;
  width: 224px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px 0;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  box-shadow: 0px 0px 16px ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  z-index: 2;
`;

const SignOutButtonWrapper = styled.div`
  margin-top: 24px;
`;

const BetaText = styled(OverlineStyle2)`
  margin-top: 20px;
`;

const DashboardHeader: React.FC = () => {
  const { currentUser, signOut, pending } = useContext(AuthContext);
  const [user, setUser] = useState(currentUser);
  const [showSignout, setShowSignout] = useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const resendInviteRef = React.useRef<HTMLDivElement>(null);
  const [showResendInvite, setShowResendInvite] = useState(false);

  const handleClick = (e: MouseEvent) => {
    // outside dropdown div
    if (!!ref.current && !ref.current.contains(e.target as Node)) {
      setShowSignout(false);
    }
    // outside of resend invite component
    if (!!resendInviteRef.current && !resendInviteRef.current.contains(e.target as Node)) {
      setShowResendInvite(false);
    }
  };

  const closeResendInvite = () => {
    setShowResendInvite(false);
  };

  useEffect(() => {
    setUser(currentUser);
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [currentUser]);

  return (
    <Header>
      <NavItems>
        <DropdownWrapper>
          <Link to="/dashboard">
            <DropdownSelected>Campaigns</DropdownSelected>
          </Link>
        </DropdownWrapper>
      </NavItems>
      <RightIcons>
        {user?.userType !== UserType.SuperAdmin && user?.userType !== UserType.Admin && (
          <Link to="/account">
            <IconWrapper>
              <Button.IconSecondary icon={SettingsIcon} />
            </IconWrapper>
          </Link>
        )}
        {(user?.userType === UserType.Admin || user?.userType === UserType.SuperAdmin) && (
          <ResendInviteContainer ref={resendInviteRef}>
            <Button.Primary
              type="button"
              size={Button.Size.small}
              disabled={showResendInvite}
              onClick={() => setShowResendInvite(true)}
            >
              Resend Invite
            </Button.Primary>
            {showResendInvite && <ResendInvite closeResendInvite={closeResendInvite} />}
          </ResendInviteContainer>
        )}
        <PersonInfoContainer ref={ref}>
          <AvatarWrapper
            onClick={() => {
              setShowSignout(!showSignout);
            }}
          >
            {user && <Avatar name={user?.firstName} />}
          </AvatarWrapper>
          <CardWrapper show={showSignout} aria-hidden={!showSignout}>
            <H4Style2>{fullName(user)}</H4Style2>
            <Caption>{user?.userType}</Caption>
            <SignOutButtonWrapper>
              <Button.Primary type="button" size={Button.Size.small} disabled={pending} onClick={() => signOut()}>
                Sign Out
              </Button.Primary>
            </SignOutButtonWrapper>
            <BetaText>Beta</BetaText>
          </CardWrapper>
        </PersonInfoContainer>
      </RightIcons>
    </Header>
  );
};

export default DashboardHeader;
