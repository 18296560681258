import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { P2Styles, Overline, H5Styles2 } from '../../Typography';
import { InputStyles } from './borderInput';
import calendarIcon from '../../../assets/images/calendar-icon.svg';

const LabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RequiredLabel = styled(Overline)`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  margin-left: 8px;
`;

const InputLabel = styled.label<{ unbold: boolean | undefined }>`
  ${({ unbold }) => (unbold ? P2Styles : H5Styles2)};
  display: inline-block;
  margin-bottom: 6px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`;

const Image = styled.img`
  width: 16px;
`;

const DatePickerStyles = createGlobalStyle`
  .date_picker {
    width: 100%;
    border: none;
    margin-left: 4px;
    outline: none;
  }
`;

const DatePickerWrapperStyles = styled.div<{ error?: boolean }>`
  ${InputStyles}
  width: 100%;
  outline: none;
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.error : theme.colors.primaryLight)};
  display: flex;
`;

export type DateSelectorProps = {
  dropdownText?: string;
  handleSelect: (option: Date) => void;
  id: string;
  label: string;
  value: Date;
  unbold?: boolean;
  hasRequiredText?: boolean;
  error?: boolean;
  onClick?: () => void;
};

const DateSelector: React.FC<DateSelectorProps> = ({
  handleSelect,
  label,
  id,
  hasRequiredText,
  unbold,
  value,
  error,
}: DateSelectorProps) => {
  const aYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  const getDaysAgo = (days: number) => new Date(new Date().setDate(new Date().getDate() - days));

  return (
    <DropdownWrapper>
      <LabelsWrapper>
        {label && (
          <InputLabel unbold={unbold} htmlFor={id}>
            {label}
          </InputLabel>
        )}
        {hasRequiredText && <RequiredLabel>Required</RequiredLabel>}
      </LabelsWrapper>
      <DatePickerWrapperStyles error={error}>
        <Image src={calendarIcon} alt="calendar" />
        <DatePicker
          className="date_picker"
          selected={value}
          onChange={handleSelect}
          includeDateIntervals={[{ start: getDaysAgo(1), end: aYearFromNow }]}
        />
      </DatePickerWrapperStyles>
      <DatePickerStyles />
    </DropdownWrapper>
  );
};

export default DateSelector;
