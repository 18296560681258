import { useEffect } from 'react';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import posthog from 'posthog-js';
import { useLocation } from 'react-router-dom';

export const usePageTracking = (): void => {
  const location = useLocation();

  useEffect(() => {
    const gaId = process.env.REACT_APP_GA_ID || '';
    ReactGA.initialize(gaId);
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
};

export const useGA4PageTracking = (): void => {
  const location = useLocation();

  useEffect(() => {
    const gaId = process.env.REACT_APP_GA4_ID || '';
    ReactGA4.initialize(gaId);
    pageView(`${location}`);
  });
};

export const pageView = (path: string) => {
  ReactGA.send({ hitType: 'pageview', page: path });
};

export const sendGAEvent = (eventTitle: string) => {
  ReactGA4.gtag('event', eventTitle);
};

export const trackClickAnalytics = () => {
  const wrapper = document.getElementById('root');

  if (wrapper === null) {
    return;
  }

  wrapper.addEventListener('click', (event) => {
    if (
      event &&
      event.target &&
      event.target instanceof Element &&
      event.target.tagName !== 'DIV' &&
      event.target.textContent &&
      event.target.textContent.length > 2
    ) {
      const sendTag = `click_${event.target.tagName
        .toLowerCase()
        .replace(' ', '_')}_${event.target.textContent.toLowerCase().replace(' ', '_')}${
        event.target.id ? `_${event.target.id.toLowerCase().replace(' ', '_')}` : ''
      }`;

      sendGAEvent(sendTag);
    }
  });
};

export const sendPostHogEvent = (eventName: string, properties: PostHogProperties, timestamp: Date = new Date()) => {
  posthog.capture(eventName, properties, { timestamp });
};
