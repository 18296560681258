import React, { useContext, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Toaster } from 'react-hot-toast';

import DashboardHeader from '../../components/common/Header/dashboardHeader';

import { AuthContext } from '../../contexts/auth-context';
import AlertServiceProvider from '../../components/common/Alert';
import Router from '../../router';

import { light } from '../../assets/styles/themes';
import { trackClickAnalytics } from '../../utils/analytics';
import Error410 from '../../components/common/Error410';

const DEFAULT_TOASTER_OFFSET = 32;

const Container = styled.div`
  overflow-x: hidden;
  height: 100%;
`;

const CustomToaster = styled(Toaster)`
  pointer-events: none;
`;

const HeaderSelector: React.FC = () => {
  const { currentUser, isHideHeader } = useContext(AuthContext);
  return <>{currentUser && !isHideHeader && <DashboardHeader />}</>;
};

const App: React.FC = () => {
  useEffect(() => {
    trackClickAnalytics();
  });

  return (
    <Container>
      {/* <Auth> */}
      <ThemeProvider theme={light}>
        <Error410 />
        {/* <CustomToaster
          position="top-right"
          reverseOrder={false}
          containerStyle={{
            top: DEFAULT_TOASTER_OFFSET,
            right: DEFAULT_TOASTER_OFFSET,
          }}
        />
        <AlertServiceProvider>
          <Router>
            <HeaderSelector />
          </Router>
        </AlertServiceProvider> */}
      </ThemeProvider>
      {/* </Auth> */}
    </Container>
  );
};

export default App;
