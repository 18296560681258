import React, { useState } from 'react';
import styled from 'styled-components';
import { H5Styles2, Caption } from '../../Typography';

import BorderInput from './borderInput';
import ShowIcon from '../../../assets/images/show-password.svg';
import HideIcon from '../../../assets/images/hide-password.svg';

const { Input } = BorderInput;

export type PasswordFieldProps = {
  label: string;
  id: string;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showPasswordIcon?: boolean;
  required?: boolean;
  spellcheck?: string;
  error?: string;
  errorMessage?: string;
};

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 4px;
`;

const InputLabel = styled.label`
  ${H5Styles2}
  display: inline-block;
  margin-bottom: 6px;
`;

const Icon = styled.img`
  position: absolute;
  width: 16px;
  top: 0;
  left: 95%;
  cursor: pointer;
`;

const ErrorMessage = styled(Caption)<{ hide: boolean }>`
  color: ${({ theme }) => theme.colors.error};
  display: ${({ hide }) => (hide ? 'none' : 'display')};
`;

const PasswordField: React.FC<PasswordFieldProps> = ({
  label,
  id,
  value,
  error,
  errorMessage,
  showPasswordIcon = false,
  placeholder = '●●●●●●●●',
  ...rest
}: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputContainer>
      {showPasswordIcon && (
        <>
          {showPassword ? (
            <Icon src={HideIcon} onClick={() => setShowPassword(false)} alt="icon of hide-password icon" />
          ) : (
            <Icon src={ShowIcon} onClick={() => setShowPassword(true)} alt="icon of show-password icon" />
          )}
        </>
      )}
      <InputLabel htmlFor="password">{label}</InputLabel>
      <Input
        {...rest}
        type={showPassword ? 'text' : 'password'}
        id={id}
        value={value}
        error={!!error}
        customStyles={undefined}
        placeholder={showPassword ? '' : placeholder}
      />
      <ErrorMessage hide={!error}>{errorMessage}</ErrorMessage>
    </InputContainer>
  );
};

export default PasswordField;
