import React, { useState, useEffect, ReactNode } from 'react';
import jwtDecode from 'jwt-decode';
import { Auth as AmplifyAuth, Hub } from 'aws-amplify';
import posthog from 'posthog-js';

import Loading from '../components/common/Loading';

import { UserType, VerificationStatus } from '../types/common';
import {
  signIn as authSignIn,
  signUp as authSignUp,
  signOut as authSignOut,
  respondToAuthChallenge as authChallengeResponse,
  AuthResponse,
} from '../api/auth';
import { getUserByUserId } from '../api/users';
import { createCookie, getCookie, deleteCookie, accessTokenKey, refreshTokenKey } from '../utils/cookies';

type CognitoResponse = {
  'cognito:username': string;
};

type AuthContextProps = {
  currentUser?: User;
  currentChallenge?: Challenge;
  setCurrentChallenge: React.Dispatch<React.SetStateAction<Challenge | undefined>>;
  signIn: (email: string, password: string) => Promise<void>;
  signInUsingOTP: (code: string) => Promise<void>;
  sendOTP: (email: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  signOut: () => void;
  getUserInfo: () => Promise<void>;
  challengeResponse: (challengeName: string, response: string, userName: string, session: string) => Promise<void>;
  pending: boolean;
  isHideHeader: boolean;
  setIsHideHeader: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AuthContext = React.createContext<AuthContextProps>({} as AuthContextProps);

type AuthProps = {
  children: ReactNode;
};

export const Auth: React.FC<AuthProps> = ({ children }: AuthProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tempCognitoUser, setTempCognitoUser] = useState<any>(undefined);
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const [currentChallenge, setCurrentChallenge] = useState<Challenge | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);
  const [isHideHeader, setIsHideHeader] = useState<boolean>(false); // Prevents dashboard header from displaying

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getCognitoUser()
            .then(({ signInUserSession }) => {
              const authData: AuthResponse = {
                accessToken: signInUserSession?.accessToken.jwtToken,
                idToken: signInUserSession?.idToken.jwtToken,
                refreshToken: signInUserSession?.refreshToken.token,
              };
              return handleAuthResponse(authData);
            })
            .then((accessTokenExists) => {
              if (accessTokenExists) {
                getUserInfo();
              }
            })
            .catch((err) => Promise.reject(err))
            .finally(() => setPending(false));
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          break;
      }
    });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-console
    getUserInfo().catch((err) => console.log('err:', err));
  }, []);

  const getCognitoUser = () => AmplifyAuth.currentAuthenticatedUser().then((userData) => userData);

  const getUserInfo = async () => {
    const userId = window.localStorage.getItem('userId');
    const refreshToken = getCookie(refreshTokenKey);
    if (refreshToken && userId) {
      setPending(true);
      return getUserByUserId()
        .then(({ data }) => {
          setCurrentUser(data);
          setCurrentChallenge(undefined);
          sendSignInToPostHog(data);
        })
        .catch((err) => Promise.reject(err))
        .finally(() => {
          setLoading(false);
          setPending(false);
        });
    }
    setLoading(false);
    return Promise.resolve();
  };

  const handleAuthResponse = (data: AuthResponse): boolean => {
    const { accessToken, idToken, refreshToken = '', challengeName, session, challengeParameters } = data;
    if (challengeName && session && challengeParameters) {
      const destination = challengeParameters.CODE_DELIVERY_DESTINATION;
      const userName = challengeParameters.USER_ID_FOR_SRP;

      setCurrentChallenge({ challengeName, session, destination, userName });
      return false;
    }
    const userId = jwtDecode<CognitoResponse>(idToken)['cognito:username'];

    window.localStorage.setItem('userId', userId);
    // expire in 30 minutes(same time as the cookie is invalidated on the backend)
    createCookie(accessTokenKey, accessToken, 0.5);
    // 30 day expiry for forced logout
    createCookie(refreshTokenKey, refreshToken, 30 * 60);
    return true;
  };

  const handleOTPAuthResponse = (accessToken: string, idToken: string, refreshToken: string): boolean => {
    const userId = jwtDecode<CognitoResponse>(idToken)['cognito:username'];

    window.localStorage.setItem('userId', userId);
    // expire in 30 minutes(same time as the cookie is invalidated on the backend)
    createCookie(accessTokenKey, accessToken, 0.5);
    // 30 day expiry for forced logout
    createCookie(refreshTokenKey, refreshToken, 30 * 60);
    return true;
  };

  const signIn = (email: string, password: string) => {
    setPending(true);
    return authSignIn(email, password)
      .then(({ data }) => handleAuthResponse(data))
      .then((accessTokenExists) => {
        if (accessTokenExists) {
          getUserInfo();
        }
      })
      .catch((err) => Promise.reject(err))
      .finally(() => setPending(false));
  };

  const signInUsingOTP = async (code: string) => {
    setPending(true);
    // Send the answer to the User Pool
    // This will throw an error if it’s the 3rd wrong answer
    await AmplifyAuth.sendCustomChallengeAnswer(tempCognitoUser, code).finally(() => {
      setPending(false);
    });

    return AmplifyAuth.currentSession().then(async (res) => {
      const jwt = res.getAccessToken().getJwtToken();
      const idToken = res.getIdToken().getJwtToken();
      const refresh = res.getRefreshToken().getToken();
      await handleOTPAuthResponse(jwt, idToken, refresh);
      await getUserInfo();
      setTempCognitoUser(undefined);
    });
  };

  const sendOTP = (email: string) => {
    const formattedEmail = email.trim().toLowerCase();

    setPending(true);
    return AmplifyAuth.signIn(formattedEmail)
      .then((user) => {
        setTempCognitoUser(user);
        setCurrentChallenge({ challengeName: 'CUSTOM_CHALLENGE', session: '' });
      })
      .catch((err) => Promise.reject(err))
      .finally(() => setPending(false));
  };

  const signUp = (email: string, password: string) => {
    setPending(true);
    return authSignUp(email, password)
      .then(({ data }) => {
        setCurrentChallenge({ challengeName: 'EMAIL_VERIFICATION', session: '' });
      })
      .catch((err) => Promise.reject(err))
      .finally(() => setPending(false));
  };

  const challengeResponse = async (challengeName: string, response: string, email: string, session: string) => {
    setPending(true);
    return authChallengeResponse(challengeName, response, email, session)
      .then(({ data }) => handleAuthResponse(data))
      .then((accessTokenExists) => {
        if (accessTokenExists) {
          getUserInfo();
        }
      })
      .catch((err) => Promise.reject(err))
      .finally(() => setPending(false));
  };

  const signOut = () => {
    setPending(true);
    setLoading(true);
    authSignOut()
      .then(async () => {
        localStorage.clear();
        setCurrentUser(undefined);
        deleteCookie(accessTokenKey);
        deleteCookie(refreshTokenKey);
        await AmplifyAuth.signOut({ global: true });
      })
      .catch((err) => Promise.reject(err))
      .finally(() => {
        setLoading(false);
        setPending(false);
      });
  };

  const sendSignInToPostHog = (user: User) => {
    posthog.identify(user.email, {
      properties: {
        user_type: user.userType,
      },
    });
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <AuthContext.Provider
          value={{
            currentUser,
            currentChallenge,
            setCurrentChallenge,
            signIn,
            signInUsingOTP,
            sendOTP,
            signUp,
            signOut,
            challengeResponse,
            pending,
            isHideHeader,
            setIsHideHeader,
            getUserInfo,
          }}
        >
          {children}
        </AuthContext.Provider>
      )}
    </>
  );
};

export const mockUser: User = {
  id: '10',
  firstName: 'John',
  lastName: 'Doe',
  email: 'johndoe@makeship.com',
  userType: UserType.Admin,
  externalId: '101',
  verificationStatus: VerificationStatus.unverified,
};

export const AuthContextMock: React.FC<AuthProps> = ({ children }: AuthProps) => (
  <AuthContext.Provider
    value={{
      currentUser: mockUser,
      signIn: () => new Promise((resolve) => resolve()),
      signInUsingOTP: () => new Promise((resolve) => resolve()),
      sendOTP: () => new Promise((resolve) => resolve()),
      signUp: () => new Promise((resolve) => true),
      signOut: () => true,
      setCurrentChallenge: () => true,
      challengeResponse: () => new Promise((resolve) => resolve()),
      pending: false,
      isHideHeader: false,
      setIsHideHeader: () => false,
      getUserInfo: () => new Promise((resolve) => resolve()),
    }}
  >
    {children}
  </AuthContext.Provider>
);
