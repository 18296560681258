import React from 'react';
import styled from 'styled-components';
import StyledLink from '@makeship/core/lib/components/Link';
import { H1, P1, P1Styles } from '../../Typography';

const Error410Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
`;
const Error410Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 638px;
  padding-top: 16px;
  background-image: url('/assets/410-art.png');
  background-repeat: no-repeat;
  background-size: ${({ theme }) => theme.breakpoints.maxContent}px auto;
  background-position: 50% 25%;
  @media (max-width: ${({ theme }) => theme.breakpoints.maxContent}px) {
    background-size: 100% auto;
    background-position: center 50%;
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    height: 683px;
    background-position: center -8%;
    background-image: url('/assets/410-art-mobile.png');
    background-size: 460px auto;
    margin-top: -16px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    height: 638px;
    background-position: center top;
    background-image: url('/assets/410-art-mobile.png');
    background-size: 360px; auto;
  }
  @media (max-width: 360px) {
    background-size: 100%; auto;
  }
`;

const Error410Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 48px;
  min-height: 504px;
  max-width: 60%;
  @media (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    width: 100%;
    position: relative;
    top: 48px;
    min-height: 0px;
    justify-content: flex-end;
    margin-bottom: 64px !important;
    margin-top: 64px;
    max-width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-bottom: 64px !important;
  }
`;

const ErrorHeader = styled(H1)`
  font-size: 92px;
  margin-bottom: 24px;
  text-transform: uppercase;
  @media (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    font-size: 48px;
    margin-bottom: 8px;
  }
`;

const ErrorSubheading = styled(P1)`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
`;

const ErrorBody = styled(P1)`
  width: 40%;
  line-height: 27px;
  margin-bottom: 24px;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    width: 100%;
    margin-bottom: 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Error410Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 100%;
  max-width: 1312px;
  @media (max-width: ${({ theme }) => theme.breakpoints.maxContent}px) {
    max-width: 100%;
    padding-left: 64px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    margin-top: 64px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-left: 16px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding-left: 16px;
    margin-top: 64px;
  }
`;

const ErrorBottomHeader = styled(P1)`
  font-weight: bold;
  margin-bottom: 8px;
`;

const BottomLink = styled(StyledLink.Primary)`
  ${P1Styles};
  color: ${({ theme }) => theme.colors.primary} !important;
  display: block;
  margin-right: 32px;
  cursor: pointer;
`;

const Error410Links = styled.div`
  display: flex;
  flex-direction: row;
`;

type Error410Props = {};

const Error410: React.FC<Error410Props> = () => (
  <Error410Page>
    <Error410Wrapper>
      <Error410Body>
        <ErrorHeader>Oops</ErrorHeader>
        <ErrorBody>
          The Creator Portal has officially retired! Please check your email to see what&apos;s next for your campaigns.{' '}
        </ErrorBody>
      </Error410Body>
    </Error410Wrapper>
  </Error410Page>
);

export default Error410;
