import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { utils, Button } from '@makeship/core';
import { P2, Caption } from '../../Typography';
import { InputField } from '../../common/Input';
import { PrimaryCard } from '../../common/Card';
import Cancel from '../../../assets/images/cancel-icon.svg';
import InviteSuccess from '../../../assets/images/invite-successful.svg';
import InviteUnsuccessful from '../../../assets/images/invite-unsuccessful.svg';
import { sendDashboardInvitation } from '../../../api/users';
import Loader from '../../common/Loader';

const CardWrapper = styled(PrimaryCard)`
  position: absolute;
  top: 45px;
  left: -192px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px 0;
  box-shadow: 0px 0px 16px ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  z-index: 3;
`;

const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 8px;
  cursor: pointer;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 50px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0px 16px;
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  align-self: center;
  margin: 0 auto;
  margin-bottom: 4px;
`;

const InviteSentTextWrapper = styled(Caption)`
  color: ${({ theme }) => theme.colors.primary};
`;

const InviteFailedTextWrapper = styled(Caption)`
  color: ${({ theme }) => theme.colors.error};
  text-align: center;
`;

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const FormWrapper = styled.form`
  width: 100%;
  padding: 4px 16px;
`;
const DisplayEmail = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral3};
  padding: 8px 0px;
  margin: 4px 0px 12px 0px;
`;
type ResendInviteProps = {
  closeResendInvite: () => void;
};

const ResendInvite: React.FC<ResendInviteProps> = ({ closeResendInvite }: ResendInviteProps) => {
  const [pendingResetInvite, setPendingResetInvite] = useState<boolean>(false);
  const [inviteSuccessful, setInviteSuccessful] = useState<boolean>(false);
  const [inviteUnsuccessful, setInviteUnsuccessful] = useState<boolean>(false);
  const [userStateException, setUserStateException] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const mounted = useRef(false);
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const onResendInvite = (e: React.SyntheticEvent) => {
    e.preventDefault();
    falsifyInviteStates();
    setEmailError('');
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Email address is in an incorrect format');
      return;
    }
    setPendingResetInvite(true);
    sendDashboardInvitation(email.trim())
      .then(() => {
        if (mounted.current) {
          setInviteSuccessful(true);
        }
      })
      .catch((err: string) => {
        if (mounted.current) {
          setInviteUnsuccessful(true);
          if (err.includes('UnsupportedUserStateException')) {
            setUserStateException(true);
          }
        }
      })
      .finally(() => {
        if (mounted.current) {
          setPendingResetInvite(false);
        }
      });
  };

  const falsifyInviteStates = () => {
    setInviteSuccessful(false);
    setInviteUnsuccessful(false);
    setUserStateException(false);
  };

  return (
    <CardWrapper>
      <Header>
        <P2>Creators Email</P2>
        <BackButtonContainer>
          <CloseIconWrapper onClick={closeResendInvite}>
            <img src={Cancel} alt="cancel icon" />
          </CloseIconWrapper>
        </BackButtonContainer>
      </Header>
      <FormWrapper onSubmit={onResendInvite} noValidate>
        {!inviteSuccessful && (
          <InputField
            id="email"
            type="email"
            error={!!emailError}
            errorMessage={emailError}
            value={email}
            label=""
            placeholder="example@email.com"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        )}
        {inviteSuccessful && (
          <DisplayEmail>
            <P2>{email}</P2>
          </DisplayEmail>
        )}
        {pendingResetInvite && (
          <IconWrapper>
            <Loader />
          </IconWrapper>
        )}

        {inviteSuccessful && (
          <ResultWrapper>
            <IconWrapper>
              <img src={InviteSuccess} alt="invite success" />
            </IconWrapper>
            <InviteSentTextWrapper>Invite Sent!</InviteSentTextWrapper>
          </ResultWrapper>
        )}
        {inviteUnsuccessful && (
          <ResultWrapper>
            <IconWrapper>
              <img src={InviteUnsuccessful} alt="invite unsuccessful" />
            </IconWrapper>
            <InviteFailedTextWrapper>
              {userStateException
                ? 'Invite failed to send! The creator already reset their password.'
                : 'Invite failed to send! There are no users in our system with this email. Contact Tech.'}
            </InviteFailedTextWrapper>
          </ResultWrapper>
        )}
        {!pendingResetInvite && !inviteSuccessful && (
          <ButtonWrapper>
            <Button.Primary type="submit" disabled={pendingResetInvite}>
              Resend Invite
            </Button.Primary>
            <Button.Secondary
              onClick={() => {
                closeResendInvite();
              }}
            >
              Cancel
            </Button.Secondary>
          </ButtonWrapper>
        )}
      </FormWrapper>
    </CardWrapper>
  );
};

export default ResendInvite;
