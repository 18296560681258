import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { P2 } from '../../Typography';

const TooltipLinkContainer = styled.div`
  display: flex;
`;

const TooltipLinkWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

const DisplayedElementsWrapper = styled.div`
  cursor: pointer;
`;

const TooltipPopup = styled.div<{
  backgroundColor: string | undefined;
  customWidth: string | undefined;
}>`
  visibility: hidden;
  border-radius: 3px;
  position: absolute;
  z-index: 2;
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.white};
  right: 0;
  top: 30px;
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.neutral6};
  ${({ customWidth }) => (customWidth ? `width: ${customWidth};` : 'width: 310px;')}

  ${TooltipLinkWrapper}:hover & {
    visibility: visible;
  }
`;

const TooltipText = styled(P2)`
  white-space: normal;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutral1};
`;

const TooltipLink = styled(P2)`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 8px;
`;

type TooltipProps = {
  content?: string | JSX.Element;
  linkText?: string;
  children?: React.ReactNode;
  backgroundColor?: string;
  customWidth?: string;
};

const Tooltip: React.FC<TooltipProps> = ({
  content,
  linkText,
  children,
  backgroundColor,
  customWidth,
}: TooltipProps) => {
  const tooltipContainer = useRef(null) as React.MutableRefObject<HTMLDivElement | null>;

  // This is the approximate pixel width between a modal and screen edge
  // Ensures tooltip is viewable in a modal when moving it back onto viewport
  const offscreenTooltipBuffer = 44;

  const isInViewport = (tooltipContainer: HTMLElement) => {
    if (tooltipContainer) {
      const rect = tooltipContainer.getBoundingClientRect();
      if (rect.left < 0) {
        const adjustedRight = Math.abs(rect.left) + offscreenTooltipBuffer;

        // eslint-disable-next-line no-param-reassign
        tooltipContainer.style.right = `-${adjustedRight}px`;
      }
    }
  };

  useEffect(() => {
    if (tooltipContainer.current) {
      isInViewport(tooltipContainer.current);
    }
  }, []);
  return (
    <TooltipLinkContainer>
      <TooltipLinkWrapper>
        <DisplayedElementsWrapper>
          {linkText && <TooltipLink>{linkText}</TooltipLink>}
          {children}
        </DisplayedElementsWrapper>
        <TooltipPopup ref={tooltipContainer} backgroundColor={backgroundColor} customWidth={customWidth}>
          {typeof content === 'string' ? <TooltipText>{content}</TooltipText> : content}
        </TooltipPopup>
      </TooltipLinkWrapper>
    </TooltipLinkContainer>
  );
};

export default Tooltip;
