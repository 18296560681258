import { utils } from '@makeship/core';
import React from 'react';
import styled from 'styled-components';
import { P2Styles, H5Styles2, Caption } from '../../Typography';

const LabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputLabel = styled.div<{ unbold: boolean | undefined }>`
  ${({ unbold }) => (unbold ? P2Styles : H5Styles2)};
  display: inline-block;
  margin-bottom: 6px;
`;

const OptionWrapper = styled.div`
  display: flex;
`;

const Option = styled.div<{ selected: boolean }>`
  &:hover {
    cursor: pointer;
  }
  display: flex;
  border-radius: 3px;
  border: 1px solid;
  padding: 8px;
  margin-right: 16px;
  accent-color: ${({ selected, theme }) => (selected ? theme.colors.primary : 'initial')};
  border-color: ${({ selected, theme }) => (selected ? theme.colors.primary : theme.colors.neutral2)};
  background-color: ${({ selected, theme }) => (selected ? theme.colors.background : 'initial')};
`;

const Label = styled.label`
  ${P2Styles}
  &:hover {
    cursor: pointer;
  }
  margin-left: 6px;
`;

const RadioSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`;

const Subtext = styled(Caption)`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  text-align: left;
  margin-bottom: 6px;
  width: 102%;
`;

export type RadioSelectProps = {
  handleSelect: (option: boolean) => void;
  label: string;
  unbold?: boolean;
  subtext?: string;
  options: { name: string; value: boolean; label: string }[];
  selected: boolean;
};

const RadioSelect: React.FC<RadioSelectProps> = ({
  handleSelect,
  label,
  unbold,
  options,
  selected,
  subtext,
}: RadioSelectProps) => (
  <RadioSelectWrapper>
    <LabelsWrapper>{label && <InputLabel unbold={unbold}>{label}</InputLabel>}</LabelsWrapper>
    <Subtext>{subtext}</Subtext>
    <OptionWrapper>
      {options?.map((option) => (
        <Option
          key={`${option.name}${option.label}`}
          onClick={() => handleSelect(option.value)}
          selected={selected === option.value}
        >
          <input
            type="radio"
            id={option.name}
            value={option.name}
            name={option.name}
            checked={selected === option.value}
            onChange={() => handleSelect(option.value)}
          />
          <Label htmlFor={option.label}>{option.label}</Label>
        </Option>
      ))}
    </OptionWrapper>
  </RadioSelectWrapper>
);

export default RadioSelect;
