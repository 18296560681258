import React from 'react';
import styled, { CSSProp } from 'styled-components';
import { utils } from '@makeship/core';
import { P2Styles } from '../../Typography';

import MagnifyingGlass from '../../../assets/images/magnifying-glass.svg';

export type SearchBarProps = {
  type?: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  spellcheck?: string;
  styles?: CSSProp;
};

const InputWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.neutral7} !important;
  background-color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  padding: 4px 15px;
  width: 365px;
  height: 32px;
  border-radius: 12px;
  align-items: center;
`;

const Input = styled.input`
  ${P2Styles}
  background-color: transparent;
  border: 2px solid transparent;
  margin-left: 5px;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral7};
    opacity: 1;
  }

  &:hover::placeholder {
    color: ${({ theme }) => theme.colors.neutral7};
  }

  &:focus {
    outline: none;
  }

  &:focus::placeholder {
    opacity: 0.5;
  }

  &:active {
    border: 2px solid transparent;
  }
`;

const SearchIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const SearchBar: React.FC<SearchBarProps> = ({ type, placeholder, value, onChange, ...rest }: SearchBarProps) => (
  <InputWrapper>
    <SearchIcon src={MagnifyingGlass} alt="magnifying glass icon" />
    <Input type={type} placeholder={placeholder} onChange={onChange} {...rest} />
  </InputWrapper>
);

export default SearchBar;
